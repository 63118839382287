import React, { useEffect, useState } from "react";
import styled from "styled-components";
import getBrowserInfo from "../util/getBrowserInfo";
import { Large, Medium, Small, Tiny, XLarge } from "../components/responsive";
import PageWrapper from "../components/PageWrapper";

const Message = styled.div`
  max-width: 500px;
  line-height: 150%;
  margin-bottom: 20px;
  text-align: center;
`;

export default function Home() {
  const [info, setInfo] = useState({});

  useEffect(() => {
    const info = getBrowserInfo();
    setInfo(info);
  }, []);

  return (
    <PageWrapper>
      <Message>We think your browser info is:</Message>
      <pre>{JSON.stringify(info, null, 2)}</pre>
      Your screen is: <XLarge>XLarge</XLarge>
      <Large>Large</Large>
      <Medium>Medium</Medium>
      <Small>Small</Small>
      <Tiny>Tiny</Tiny>
    </PageWrapper>
  );
}
